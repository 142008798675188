<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" name="form" :model="formState" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" name="cinemaId" label="消费影院">
            <!-- @change="getCardPolicyList" -->
            <a-select
              placeholder="请选择"
              v-model:value="formState.cinemaId"
              style="width: 250px"
            >
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option
                v-for="item in cinemaAllList"
                :vlaue="item.id"
                :key="item.id"
              >
                {{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="订单编号" name="orderNo" class="ui-form__item">
            <a-input
              v-model:value="formState.orderNo"
              placeholder="请输入订单号"
            ></a-input>
          </a-form-item>
          <a-form-item label="卡号" name="cardNumber" class="ui-form__item">
            <a-input
              v-model:value="formState.cardNumber"
              placeholder="请输入卡号"
            ></a-input>
          </a-form-item>

        </a-row>

        <a-row v-show="showAll">
          <a-form-item label="手机号" name="phone" class="ui-form__item">
            <a-input
              v-model:value="formState.phone"
              placeholder="请输入手机号"
            ></a-input>
          </a-form-item>
          <a-form-item label="卖品名称" name="goodsTitle" class="ui-form__item">
            <a-input
              v-model:value="formState.goodsTitle"
              placeholder="请输入卖品名称"
            ></a-input>
          </a-form-item>
          <a-form-item label="订单状态类型" name="status" class="ui-form__item">
            <a-select
              v-model:value="formState.status"
              style="width: 190px"
              placeholder="请选择订单类型"
            >
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option :value="'WAIT_CHECK'">待核销</a-select-option>
              <a-select-option :value="'TRADE_FINISH'">订单完成</a-select-option>
              <a-select-option :value="'AFTERSALE_FINISH'">交易关闭</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="ui-form__item" label="开卡影院" name="openCardCinemaId">
            <a-select placeholder="请选择" v-model:value="formState.openCardCinemaId" style="width: 180px;">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="异店消费" name="isDifferent" class="ui-form__item">
            <a-select v-model:value="formState.isDifferent" placeholder="请选择消费状态" style="width: 190px;">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="0">否</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="下单时间" class="ui-form__item">
            <a-range-picker v-model:value="payTime"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <span v-permission="['report_stored_sellingGoods_export']" style="margin-left: 20px;">
							<ExportReport type="baseRechargeCardVolume" :searchData="searchData"></ExportReport>
						</span>
            <a style="margin-left: 20px" @click="showAll = !showAll">
              {{ showAll ? "收起" : "展开" }}
              <Icon v-show="!showAll" icon="DownOutlined"></Icon>
              <Icon v-show="showAll" icon="UpOutlined"></Icon>
            </a>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button type="primary" html-type="submit">搜索</a-button>
            <a-button style="margin-left: 20px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        style="margin-top: 10px"
        :dataSource="list"
        :columns="columns"
        :pagination="pagination"
        :scroll="{ x: 1600 }"
      >
        <template #bodyCell="{ column, record, index }">
          <template v-if="column.key === 'payTime'">
            {{ transDateTime(record.createTime) }}
          </template>
          <template v-if="column.key === 'goodsInfo'">
            <div class="ui-goods" v-for="item in record.goodsInfoVOS" :key="item.id">
              <div style="display: flex;align-items: flex-start;">
                <a-image style="width: 50px;border-radius: 4px;" :src="item.imgUrl.split(',')[0]"></a-image>
                <div style="margin-left: 8px;">
                  <div style="font-size: 12px;">
                    <a-tag v-if="item.isCard" style="padding: 2px;line-height: 12px;" color="cyan" size="small">次卡</a-tag>
                    {{ item.mainTitle }}
                  </div>
                  <div style="margin-top: 2px;font-size: 14px;color: orange;">￥{{ item.nowPrice }}
                    <span style="margin-left: 10px;color:#999;">x{{ item.buyNum }}</span>
                  </div>
                </div>
              </div>
              <div v-if="item.isCard" style="margin-top: 4px;font-size: 12px;">
                <div>卡等级: {{ item?.cardVolumeVO?.title }}</div>
                <div v-if="item.cardNumberList">卡号:
                  <span v-for="(subItem, subIndex) in item.cardNumberList">
										{{ subItem }}
										<span v-if="subIndex < item.cardNumberList.length - 1">、</span>
									</span>
                </div>
                <div>观影次数: {{ item.cardBalanceTimes }}</div>
              </div>
              <div v-if="item.isCoupon" style="margin-top: 4px;font-size: 12px;">
                <div style="margin-bottom: 5px;" v-if="item.ticketBatchNum">
                  <div>兑换券批次号: {{ item.ticketCouponList.length ? item.ticketCouponList[0].batchNo : '' }}</div>
                  <div>兑换券数量: {{ item.ticketBatchNum }}张 x{{ item.buyNum }}</div>
                  <div>
                    兑换券号：
                    <a-tag v-for="(item, i) in item.ticketCouponList" :key="i">
                      {{ item.barcode }}
                    </a-tag>
                  </div>
                </div>
                <div v-if="item.snackBatchNum">
                  <a-divider style="margin: 6px 0;" v-if="item.ticketBatchNum"/>
                  <div>卖品券批次号: {{ item.snackCouponList.length ? item.snackCouponList[0].batchNo : '' }}</div>
                  <div>卖品券数量: {{ item.snackBatchNum }}张 x{{ item.buyNum }}</div>
                  <div>卖品券号:
                    <a-tag v-for="(item, i) in item.snackCouponList" :key="i">
                      {{ item.barcode }}
                    </a-tag>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="column.key === 'status'">
            {{ transOrderStatus(record.status) }}
          </template>
        </template>
        <template #summary v-if="list.length">
          <a-table-summary-row>
            <a-table-summary-cell></a-table-summary-cell>
            <a-table-summary-cell></a-table-summary-cell>
            <a-table-summary-cell></a-table-summary-cell>
            <a-table-summary-cell></a-table-summary-cell>
            <a-table-summary-cell></a-table-summary-cell>
            <a-table-summary-cell></a-table-summary-cell>
            <a-table-summary-cell>
              合计：{{ count.balanceDeductionSum || 0 }} 元
            </a-table-summary-cell>
            <a-table-summary-cell></a-table-summary-cell>
            <a-table-summary-cell>
            </a-table-summary-cell>
            <a-table-summary-cell></a-table-summary-cell>
            <a-table-summary-cell></a-table-summary-cell>
            <a-table-summary-cell></a-table-summary-cell>
            <a-table-summary-cell></a-table-summary-cell>
          </a-table-summary-row>
        </template>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
import { getCardSaleList } from "@/service/modules/coupon.js";
import {
  getOrganizationList,
  getCinemaList,
} from "@/service/modules/cinema.js";
import moment from "moment";
import ExportReport from "@/components/exportReport/exportReport.vue";
export default {
  components: { Icon, ExportReport},
  data() {
    return {
      loading: false,
      showAll: false,
      organizationList: [],
      cinemaAllList: [],
      cardAllList: [],
      formState: {
        cinemaId: "",
        couponType: 6,
        saleType: 2,
        status:'',
        openCardCinemaId:'',
        isDifferent:'',
      },
      startTime: [],
      endTime: [],
      searchData: {
      },
      list: [],
      payTime: [],
      columns: [
        {
          title: "开卡影院",
          dataIndex: "openCardCinemaName",

        },
        {
          title: "订单编号",
          dataIndex: "orderNo",
        },
        {
          title: "卡号",
          dataIndex: "cardNumber",
        },
        {
          title: "手机号",
          dataIndex: "userPhone",
        },
        {
          title: "消费影院",
          dataIndex: "cinemaName",

        },
        {
          title: "卖品信息",
          key: "goodsInfo",
        },
        {
          title: "交易金额",
          dataIndex: "balanceDeduction",
        },
        {
          title: "订单状态",
          key: "status",
        },
        {
          title: "下单时间",
          key: "payTime",
        },
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ["10", "20", "50", "100", "500"],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        },
      },
    };
  },
  created() {
    // this.onSearch();
    this.getOrganizationList();
  },
  methods: {
    onSearch() {
      this.pagination.current = 1;
      // //this.pagination.pageSize = 10;
      this.searchData = JSON.parse(JSON.stringify(this.formState));

      this.searchData.cinemaId = this.searchData.cinemaId
        ? this.searchData.cinemaId
        : undefined;
      this.searchData.status = this.searchData.status
        ? this.searchData.status
        : undefined;
      if (this.payTime && this.payTime.length) {
        this.searchData.startPayTime = this.payTime[0].startOf("days").unix();
        this.searchData.endPayTime = this.payTime[1].endOf("days").unix();
      }
      this.searchData.isDifferent = this.searchData.isDifferent != '' ? this.searchData.isDifferent : undefined
      this.searchData.openCardCinemaId = this.searchData.openCardCinemaId != ''? this.searchData.openCardCinemaId : undefined
      this.getData();
    },
    reset() {
      this.payTime = [];
      this.$refs.form.resetFields();
      if (this.organizationList.length) {
        this.formState.organizationId = this.organizationList[0].id;
        this.getAllCinemaList(this.formState.organizationId);
      }
      this.onSearch();
    },
    async getData() {
      const postData = {
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,

        ...this.searchData,
      };
      this.loading = true;
      try {
        let { data } = await getCardSaleList(postData);
        this.loading = false;
        this.count = data.list[0];
        this.list = data.list.slice(1);
        this.pagination.total = data.totalCount;
      } catch (error) {
        this.loading = false;
      }
    },
    onSubmit() {
      this.$refs.addForm.validateFields().then(() => {});
    },
    onDisplay() {
      this.isShow = !this.isShow;
    },
    async getOrganizationList() {
      this.loading = true;
      try {
        let ret = await getOrganizationList({
          page: 1,
          pageSize: 999999,
        });
        this.loading = false;
        if (ret.code === 200) {
          this.organizationList = ret.data.list;
          if (this.organizationList.length) {
            this.formState.organizationId = this.organizationList[0].id;
            this.getAllCinemaList(this.formState.organizationId);
          }
          this.onSearch();
        } else {
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getAllCinemaList(organizationId) {
      this.cinemaAllList = [];
      this.formState.cinemaId = "";
      let ret = await getCinemaList({
        page: 1,
        pageSize: 999999,
        organizationId: organizationId ? organizationId : undefined,
      });
      if (ret.code === 200) {
        this.cinemaAllList = ret.data.list;
      }
    },
    transOrderStatus(status){
      const orderStatus = {
        'WAIT_BUYER_PAY': '待支付',
        'SYSTEM_CANCEL': '系统超时取消',
        'CUSTOMER_CANCEL': '用户自行取消',
        'WAIT_SELLER_SEND_GOODS': '待发货',
        'WAIT_CHECK': '待核销',
        'TRADE_FINISH': '已核销',
        'WAIT_BUYER_CONFIRM_GOODS': '待收货',
        'AFTERSALE_FINISH': '交易关闭',
        'CHECKOUT_SUCCESS': '已付款',
        'TRADE_FINISH': '订单完成',
      }
      return orderStatus[status];
    },
  },
};
</script>

<style scoped>
.ui-form__item {
  margin-right: 20px;
}
.ui-goods {
  padding: 6px 10px;
  border-radius: 10px;
  border: solid 1px #EEE;
}
.ui-goods + .ui-goods {
  margin-top: 4px;
}
</style>
